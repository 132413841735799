<div class="container">
    <nb-card >
        <div class="close-icon iconAnimation" (click)="close()" *ngIf="showCloseButton">
            <i class="fas fa-times"></i>
        </div>
        <nb-card-body>
            <div
                class="text-center" 
                [nbSpinner]="true" nbSpinnerSize="small" nbSpinnerStatus="primary">
                {{message}}
            </div>
        </nb-card-body>
    </nb-card>
</div>
