export class ServicesUrl {
  auth: string;

  mail: string;

  drive: string;

  onlyoffice: string;

  website: string;

  chat: string;

  synapse: string;

  constructor(
    auth: string,
    mail: string,
    drive: string,
    onlyoffice: string,
    website: string,
    chat: string,
    synapse: string
  ) {
    this.auth = auth
    this.mail = mail
    this.drive = drive
    this.onlyoffice = onlyoffice
    this.website = website
    this.chat = chat
    this.synapse = synapse
  }


}
