<div class="container">
  <h5>Création d'un nouveau connecteur</h5>
  <form [formGroup]="form" (submit)="submit()">
    <label><strong>Connecteur Id: </strong></label>
    <input type="text" nbInput fullWidth shape="semi-round" placeholder="id du connecteur" formControlName="connector_id">
    <br>
    <label><strong>Nom: </strong></label>
    <input type="text" nbInput fullWidth shape="semi-round" placeholder="nom du connecteur" formControlName="name">
    <br>
    <label for="description"></label><strong>Description: </strong>
    <textarea rows="3" cols="40" nbInput fullWidth shape="round"  placeholder="Description" id="description" formControlName="description"></textarea>
    <br>
    <label><strong>Les services nécessaires pour le connecteur : </strong></label>
    <nb-select multiple class="dropdown" formControlName="depends_on" placeholder="depends_on">
      <nb-option *ngFor="let service of services" value="{{service.service_id}}">
        {{service.name}}
      </nb-option>
    </nb-select>

    <div class="btn-class">
      <button class="cancel" nbButton status="danger" (click)="cancel()">Annuler</button>
      <button class="valider" nbButton status="primary" type="submit" [disabled]="form.invalid" [ngClass]="{'disabled': form.invalid}">Valider</button>
    </div>
  </form>
</div>
