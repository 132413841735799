<div class="container">
  <h5>Mise à jour du service {{service.name}}</h5>
  <form [formGroup]="form" (submit)="submit()">
    <label><strong>Service Id: </strong></label>
    <input type="text" nbInput fullWidth shape="semi-round" placeholder="id du service" formControlName="service_id">
    <br>
    <label><strong>Nom: </strong></label>
    <input type="text" nbInput fullWidth shape="semi-round" placeholder="nom du service" formControlName="name">
    <br>
    <label for="description"></label><strong>Description: </strong>
    <textarea rows="3" cols="40" nbInput fullWidth shape="round"  placeholder="Description" id="description" formControlName="description"></textarea>

    <div class="btn-class">
      <button class="cancel" nbButton status="danger" (click)="cancel()">Annuler</button>
      <button class="valider" nbButton status="primary" type="submit" [disabled]="form.invalid" [ngClass]="{'disabled': form.invalid}">Valider</button>
    </div>
  </form>
</div>
