import {Component, Input} from '@angular/core';
import {NbDialogRef} from "@nebular/theme";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'ngx-connector-form',
  standalone: false,
  templateUrl: './connector-form.component.html',
  styleUrl: './connector-form.component.scss'
})
export class ConnectorFormComponent {
  @Input() services: any;

  form = new FormGroup({
    connector_id: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    depends_on: new FormControl([]),
  });

  public constructor(protected ref: NbDialogRef<ConnectorFormComponent>){}

  ngOnInit(){

  }

  submit(){
    if(this.form.valid){
      this.ref.close(this.form.value);
    }
    else {
      this.ref.close()
    }
  }

  cancel(){
    this.ref.close();
  }
}
