import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';import {  NbDialogRef } from '@nebular/theme';
import { Organization } from '../../../../Models/organization/entities/organization.entity';
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'ngx-update-organization-form',
  templateUrl: './update-organization-form.component.html',
  styleUrls: ['./update-organization-form.component.scss']
})
export class UpdateOrganizationFormComponent implements OnInit {

  public constructor(protected ref: NbDialogRef<UpdateOrganizationFormComponent>){}

  @Input() organization : Organization;

  servicesUrlsPlaceholder : string = 'Exemple: https://www.exemple.fr';

  k8sProviders: string[] = environment.K8S_PROVIDERS_LIST;


  form = new UntypedFormGroup({
    // organization_id: new FormControl('', Validators.required),
    name: new UntypedFormControl('', Validators.required),
    is_root: new UntypedFormControl(false, Validators.required),
    auth: new UntypedFormControl('', Validators.required),
    mail: new UntypedFormControl('', Validators.required),
    drive: new UntypedFormControl('', Validators.required),
    website: new UntypedFormControl('', Validators.required),
    chat: new UntypedFormControl('', Validators.required),
    synapse: new UntypedFormControl('', Validators.required),
    onlyoffice: new UntypedFormControl('', Validators.required),
    NEXT_CLOUD_USERNAME: new UntypedFormControl( '', Validators.required),
    NEXT_CLOUD_PASSWORD: new UntypedFormControl('', Validators.required),
    MAILSERVER_POSTMASTER_USERNAME: new UntypedFormControl('', Validators.required),
    MAILSERVER_POSTMASTER_PASSWORD: new UntypedFormControl('', Validators.required),
    KC_SERVICE_ADMIN_USERNAME: new UntypedFormControl('', Validators.required),
    KC_SERVICE_ADMIN_PASSWORD: new UntypedFormControl('', Validators.required),
    HOST_DATA_SSH_PORT: new UntypedFormControl('', Validators.required),
    SFTP_HOST: new UntypedFormControl('', Validators.required),
    SFTP_PORT: new UntypedFormControl('', Validators.required),
    SFTP_USERNAME: new UntypedFormControl('', Validators.required),
    SFTP_PASSWORD: new UntypedFormControl('', Validators.required),
    KC_SERVICE_ADMIN_CLIENT_ID: new UntypedFormControl('', Validators.required),
    KC_SERVICE_URL: new UntypedFormControl('', Validators.required),
    ORG_DOMAIN: new UntypedFormControl('', Validators.required),
    K8S_PROVIDER: new UntypedFormControl('', Validators.required),
    ORG_NAMESPACE: new UntypedFormControl('', Validators.required),
  });

  ngOnInit(){
    //initialize form values
    if(this.organization){
      this.form.patchValue({
        name: this.organization.name || '',
        is_root: this.organization.is_root || false,
        auth: this.organization.organization_env?.services_url?.auth || '',
        mail: this.organization.organization_env?.services_url?.mail || '',
        drive: this.organization.organization_env?.services_url?.drive || '',
        website: this.organization.organization_env?.services_url?.website || '',
        chat: this.organization.organization_env?.services_url?.chat || '',
        synapse: this.organization.organization_env?.services_url?.synapse || '',
        onlyoffice: this.organization.organization_env?.services_url?.onlyoffice || '',
        NEXT_CLOUD_USERNAME: this.organization.organization_env?.services_credentials?.NEXT_CLOUD_USERNAME || '',
        NEXT_CLOUD_PASSWORD: this.organization.organization_env?.services_credentials?.NEXT_CLOUD_PASSWORD || '',
        MAILSERVER_POSTMASTER_USERNAME: this.organization.organization_env?.services_credentials?.MAILSERVER_POSTMASTER_USERNAME || '',
        MAILSERVER_POSTMASTER_PASSWORD: this.organization.organization_env?.services_credentials?.MAILSERVER_POSTMASTER_PASSWORD || '',
        KC_SERVICE_ADMIN_USERNAME: this.organization.organization_env?.services_credentials?.KC_SERVICE_ADMIN_USERNAME || '',
        KC_SERVICE_ADMIN_PASSWORD: this.organization.organization_env?.services_credentials?.KC_SERVICE_ADMIN_PASSWORD || '',
        HOST_DATA_SSH_PORT: this.organization.organization_env?.HOST_DATA_SSH_PORT || '',
        SFTP_HOST: this.organization.organization_env?.SFTP_HOST || '',
        SFTP_PORT: this.organization.organization_env?.SFTP_PORT || '',
        SFTP_USERNAME: this.organization.organization_env?.SFTP_USERNAME || '',
        SFTP_PASSWORD: this.organization.organization_env?.SFTP_PASSWORD || '',
        KC_SERVICE_ADMIN_CLIENT_ID: this.organization.organization_env?.KC_SERVICE_ADMIN_CLIENT_ID || '',
        KC_SERVICE_URL: this.organization.organization_env?.KC_SERVICE_URL || '',
        ORG_DOMAIN: this.organization.organization_env?.ORG_DOMAIN || '',
        K8S_PROVIDER: this.organization.organization_env?.K8S_PROVIDER || '',
        ORG_NAMESPACE: this.organization.organization_env?.ORG_NAMESPACE || '',
      })

      // Écouter les changements sur le champ "name"
      this.form.get('name').valueChanges.subscribe((value) => {
        if (value) {
          // Convertir en minuscules et remplacer les espaces par des tirets
          const domain = value.toLowerCase().replace(/\s+/g, '-'); 
          this.form.patchValue({
            ORG_DOMAIN: `${domain}.krisalee.fr`,
            auth: `https://auth.${domain}.krisalee.fr`,
            mail: `https://webmail.${domain}.krisalee.fr`,
            drive: `https://drive.${domain}.krisalee.fr`,
            website: `https://website.${domain}.krisalee.fr`,
            chat: `https://chat.${domain}.krisalee.fr`,
            synapse: `https://synapse.${domain}.krisalee.fr`,
            onlyoffice: `https://onlyoffice.${domain}.krisalee.fr`,
          });
        }
      });
    }

  }

  submit(){
    if(this.form.valid){
      this.ref.close(this.form.value);
    }
    else {
      this.ref.close()
    }
  }

  cancel(){
    this.ref.close();
  }
}
