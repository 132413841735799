import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UpdateServiceFormComponent} from "./update-service-form.component";
import {NbInputModule, NbSelectModule} from "@nebular/theme";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";



@NgModule({
  declarations: [UpdateServiceFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NbInputModule,
    NbSelectModule,
  ],
  exports:[
    UpdateServiceFormComponent,
  ]
})
export class UpdateServiceFormModule { }
