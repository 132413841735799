export class setupServicesDTO {
    public services: service[] = [];

}

export class service{
    service_id : string;
    product_id : string;
    version_id: string;
    depends_on: string[] = [];
}
