import {Component, Input} from '@angular/core';
import {NbDialogRef} from "@nebular/theme";
import {ProductDTO} from "../../../../Models/product/productAdd-dto";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'ngx-update-product-form',
  templateUrl: './update-product-form.component.html',
  styleUrl: './update-product-form.component.scss'
})
export class UpdateProductFormComponent {
  public  constructor(protected ref: NbDialogRef<UpdateProductFormComponent>) { }


  @Input() services: any;
  @Input() organizations: any;
  @Input() product : ProductDTO;

  form = new FormGroup({
    product_id: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    service_id: new FormControl('',Validators.required),
    organizations: new FormArray([]),
    depends_on: new FormArray([]),
  });

  ngOnInit(){
    //initialize form values
    if(this.product){
      this.form.patchValue({
        name: this.product.name || '',
        description: this.product.description || '',
        service_id: this.product.service_id || '',
        product_id: this.product.product_id || '',
      })
    }
  }

  submit(){
    if(this.form.valid){
      this.ref.close(this.form.value);
    }
    else {
      this.ref.close()
    }
  }

  cancel(){
    this.ref.close();
  }
}
