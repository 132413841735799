import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';


@Component({
  selector: 'ngx-user-details-dialog',
  templateUrl: './user-details-dialog.component.html',
  styleUrls: ['./user-details-dialog.component.scss']
})
export class UserDetailsDialogComponent implements OnInit {
  @Input() user: any;
  @Input() alert: Function;
  @Output() deleteUser: EventEmitter<any> = new EventEmitter();
  @Output() updateUser: EventEmitter<any> = new EventEmitter();
  @Output() addUser: EventEmitter<any> = new EventEmitter();
  editMode: boolean = false;
  originalUser: any;
  userForm: FormGroup;

  constructor(
    protected ref: NbDialogRef<UserDetailsDialogComponent>,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    if (!this.user) {
      this.editMode = true;
    }
    const personalDate = this.user?.personalDate ? new Date(this.user.personalDate) : '';


    // Zero out time components to avoid timezone issues
    if (personalDate) {
      personalDate.setHours(0, 0, 0, 0);
    }

    this.originalUser = {
      firstName: this.user?.firstName || '',
      lastName: this.user?.lastName || '',
      jobDepartment: this.user?.jobDepartment || '',
      jobTitle: this.user?.jobTitle || '',
      email: this.user?.email || '',
      username: this.user?.username || '',
      admin: this.user?.admin || false,
      alert_level: this.user?.alert_level || '',
      phoneNumber: this.user?.phoneNumber || '',
      personalDate: personalDate
    };

    this.userForm = this.fb.group({
      firstName: [this.user?.firstName || '', [Validators.required]],
      lastName: [this.user?.lastName || '', [Validators.required]],
      jobDepartment: [this.user?.jobDepartment || '', [Validators.required]],
      jobTitle: [this.user?.jobTitle || '', [Validators.required]],
      username: [this.user?.username || '', [Validators.required]],
      admin: [this.user?.admin || false],
      alert_level: [this.user?.alert_level || '', [Validators.required]],
      phoneNumber: [this.user?.phoneNumber || '', [Validators.required, this.phoneNumberValidator]],
      personalDate: [personalDate || null, [Validators.required]]
    });
  }

  toggleEdit() {
    this.editMode = true;
  }

  saveChanges() {
    if (this.userForm.valid) {
      if (this.user) {
        const updateUserDto = this.getChangedProperties();
        this.updateUser.emit({ id: this.user.id, updateUserDto });
      } else {
        const userData = this.userForm.value;
        const untimezonedDate = new Date(Date.UTC(userData.personalDate.getFullYear(), userData.personalDate.getMonth(), userData.personalDate.getDate()));
        userData.personalDate = untimezonedDate.toISOString().split('T')[0];
        this.addUser.emit(userData);
      }
      this.close();
    }
  }

  cancelEdit() {
    // Réinitialiser l'utilisateur aux valeurs originales
    this.userForm.reset(this.originalUser);
    this.editMode = false;
  }

  confirmDelete(user: any) {
    this.deleteUser.emit(user);
    this.close();
  }

  showAlert(user: any) {
    this.alert(user); // Call the alert method passed from parent component
  }

  close() {
    this.ref.close();
  }

  phoneNumberValidator(control?: any) {
    const phoneNumberRegex = /^\+33\d{9}$|^0\d{9}$/;
    return phoneNumberRegex.test(control?.value) ? null : { invalidPhoneNumber: true };
  }

  userHasChanged(): boolean {
    const formValues = this.userForm.value;
    const originalValues = this.originalUser;

    // Convert formValues.personalDate to string if it's a Date object
    for (const key in formValues) {
      if (formValues.hasOwnProperty(key)) {
        if (key === 'personalDate') {
          const formDateStr = formValues.personalDate instanceof Date ?formValues.personalDate.toISOString().split('T')[0] : formValues.personalDate;
          const originalDateStr = originalValues.personalDate?.toISOString().split('T')[0];
          return formDateStr !== originalDateStr;
        } else {
          if (formValues[key] !== originalValues[key]) {
            return true;
          }
        }
      }
    }
    return false;
  }

  getChangedProperties(): any {
    const changedProperties: any = {};
    const formValues = this.userForm.value;
    const originalValues = this.originalUser;

    for (const key in formValues) {
      if (formValues.hasOwnProperty(key)) {
        if (key === 'personalDate') {
          // if (formValues[key] instanceof Date && originalValues[key] instanceof Date) {
          //   if (formValues[key].getTime() !== originalValues[key].getTime()) {
          //     const untimezonedDate = new Date(Date.UTC(formValues[key].getFullYear(), formValues[key].getMonth(), formValues[key].getDate()));
          //     changedProperties[key] = untimezonedDate.toISOString().split('T')[0];
          //   }
          // } else if (formValues[key] !== originalValues[key]) {
          //   changedProperties[key] = formValues[key];
          // }
          // Compare Date objects
          if (formValues[key] instanceof Date) {
            const untimezonedDate = new Date(Date.UTC(formValues[key].getFullYear(), formValues[key].getMonth(), formValues[key].getDate()));
            changedProperties[key] = untimezonedDate.toISOString().split('T')[0];
          }
        } else {
          if (formValues[key] !== originalValues[key]) {
            changedProperties[key] = formValues[key];
          }
        }
      }
    }
    return changedProperties;
  }
}
