import { Component, Input, OnInit } from '@angular/core';
import {  NbDialogRef } from '@nebular/theme';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ServiceDTO } from '../../../../Models/serviceAdd-dto';
@Component({
  selector: 'ngx-update-service-form',
  templateUrl: './update-service-form.component.html',
  styleUrl: './update-service-form.component.scss'
})
export class UpdateServiceFormComponent {
  public constructor(protected ref: NbDialogRef<UpdateServiceFormComponent>){}

  @Input() service : ServiceDTO;
  private initialFormValue: any = {}; // Store the initial form values

  form = new FormGroup({
    service_id: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
  });

  ngOnInit(){
    //initialize form values
    if(this.service){
      this.form.patchValue({
        service_id: this.service.service_id || '',
        name: this.service.name || '',
        description: this.service.description || '',
      })
    }
    // Save the initial values to compare later
    this.initialFormValue = this.form.getRawValue();
  }

  submit(){
    const currentFormValue = this.form.getRawValue();

    // Compare the initial and current form values
    if (JSON.stringify(this.initialFormValue) === JSON.stringify(currentFormValue)) {
      this.ref.close(); // Close the form without sending a request
      return;
      }
    if(this.form.valid){
      this.ref.close(this.form.value);
    }
    else {
      this.ref.close()
    }
  }

  cancel(){
    this.ref.close();
  }
}
