import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, UntypedFormControl } from '@angular/forms';
import {  NbDialogRef, NbDialogService, NbStepperComponent } from '@nebular/theme';
import { ExecutingRequestComponent } from '../../../../Components/executing-request/executing-request.component';
import { ServicesUrl } from '../../../../Models/organization/entities/ServicesUrl.entity';
import { ServicesCredentials } from '../../../../Models/organization/entities/ServicesCredentials.entity';
import { OrganizationEnv } from '../../../../Models/organization/entities/organizationEnv.entity';
import { Organization } from '../../../../Models/organization/entities/organization.entity';
import { OrganizationsService } from '../../../../Services/organizations/organizations.service';
import { SuccessHandlerService } from '../../../../Services/successHandler/success-handler.service';
import { error } from 'console';
import { ErrorHandlerService } from '../../../../Services/errorHandler/error-handler.service';
import { NbToastrService } from '@nebular/theme';
import { FileUploadModalComponent } from '../../../../Components/file-upload-modal/file-upload-modal.component';
import { urlValidator } from '../../../../helpers/custom-validators';
import { setupServicesDTO } from '../../../../Models/organization/setupServices-dto';
import { OrganizationCreationStep } from '../../../../Models/enums/organizationCreationEnum';
import { storeIpAddressesDTO } from '../../../../Models/organization/storeIpAddresses-dto';
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'ngx-organization-form',
  templateUrl: './organization-form.component.html',
  styleUrls: ['./organization-form.component.scss']
})
export class OrganizationFormComponent {

  @ViewChild("stepper") stepper: NbStepperComponent;

  @Input() organization: any;

  @Input() products: any;

  @Input() services: any;

  /** a variable responsible for showing or hiding the form for Configuration Mode */
  isFormControlVisible: boolean = false;

  servicesUrlsPlaceholder : string = 'Exemple: https://www.exemple.fr';

  forceShowErrors: boolean = false;
  forceShowErrorsForLoadBalancersForm: boolean = false;

  /** a variable to indicates whether the user has confirmed generating and loading the certificates */
  certChecked: boolean = false;

  /** a variable to indicates whether the user has configured dns entries */
  dnsConfigured: boolean = false;

  /** a variable to indicates whether the user has configured dns entries for dkim certificates */
  dkimCertsConfigured: boolean = false;

  linearMode = true;

  /** - a variable to hold the frontal service object */
  frontalService: any;

  k8sProviders: string[] = environment.K8S_PROVIDERS_LIST;


  form = new FormGroup({
    // organization_id: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    is_root: new FormControl(false, Validators.required),
    auth: new FormControl('https://', [Validators.required, urlValidator]),
    mail: new FormControl('https://', [Validators.required, urlValidator]),
    drive: new FormControl('https://', [Validators.required, urlValidator]),
    onlyoffice: new FormControl('https://', [Validators.required, urlValidator]),
    website: new FormControl('https://', [Validators.required, urlValidator]),
    chat: new FormControl('https://', [Validators.required, urlValidator]),
    synapse: new FormControl('https://', [Validators.required, urlValidator]),
    NEXT_CLOUD_USERNAME: new FormControl('', Validators.required),
    NEXT_CLOUD_PASSWORD: new FormControl('', Validators.required),
    MAILSERVER_POSTMASTER_USERNAME: new FormControl('', Validators.required),
    MAILSERVER_POSTMASTER_PASSWORD: new FormControl('', Validators.required),
    KC_SERVICE_ADMIN_USERNAME: new FormControl('', Validators.required),
    KC_SERVICE_ADMIN_PASSWORD: new FormControl('', Validators.required),
    HOST_DATA_SSH_PORT: new UntypedFormControl('', Validators.required),
    SFTP_HOST: new FormControl('', Validators.required),
    SFTP_PORT: new UntypedFormControl('', Validators.required),
    SFTP_USERNAME: new FormControl('', Validators.required),
    SFTP_PASSWORD: new FormControl('', Validators.required),
    KC_SERVICE_ADMIN_CLIENT_ID: new FormControl('', Validators.required),
    KC_SERVICE_URL: new FormControl('', Validators.required),
    ORG_DOMAIN: new FormControl('', Validators.required),
    K8S_PROVIDER: new FormControl('', Validators.required),
    ORG_NAMESPACE: new FormControl('', Validators.required),
  });

  frontalServiceForm = new FormGroup({
    version_id : new FormControl('', Validators.required),
    depends_on: new FormControl([]),
  });

  servicesForm = new FormGroup({
    services_to_launch: new FormArray([]),
  });

  LoadBalancersForm = new FormGroup({
    globalIpAddress: new FormControl('', Validators.required),
    loadBalancerIpAddress: new FormControl('', Validators.required),
    servicesGatewayIpAddress: new FormControl('', Validators.required),
  })

  public constructor(protected ref: NbDialogRef<OrganizationFormComponent>,
    private dialogService: NbDialogService,
    private organizationService: OrganizationsService,
    private successHandler: SuccessHandlerService,
    private errorHandler: ErrorHandlerService,
    private toastrService: NbToastrService
  ){}

  ngOnInit(){
    if(this.products){
      this.frontalService = this.products.find(product => product.service_id == 'frontal');
    }
    //initialize form values
    if(this.organization){
      // retrieve the organization to get new updates
      this.organizationService.getOrganization(this.organization.organization_id).subscribe(response => {
        this.organization = response;
        if(this.organization.creationStatus.step == OrganizationCreationStep.FIFTH_STEP &&
           this.organization?.creationStatus?.isStepCreating)
        {
          let dialogRef = this.showExexutingOperationModal(`Initialisation des services en cours...`);
        } else if(this.organization.creationStatus.step == OrganizationCreationStep.FOURTH_STEP &&
           this.organization?.creationStatus?.isStepCreating)
        {
          let dialogRef = this.showExexutingOperationModal(`Initialisation du service frontal en cours...`);
        }
      });

      if(!this.organization.creationStatus){
        this.form.patchValue({
          name: this.organization.name || '',
          is_root: this.organization.is_root || false,
          auth: this.organization.organization_env?.services_url?.auth || '',
          mail: this.organization.organization_env?.services_url?.mail || '',
          drive: this.organization.organization_env?.services_url?.drive || '',
          onlyoffice: this.organization.organization_env?.services_url?.onlyoffice || '',
          website: this.organization.organization_env?.services_url?.website || '',
          chat: this.organization.organization_env?.services_url?.chat || '',
          synapse: this.organization.organization_env?.services_url?.synapse || '',
          NEXT_CLOUD_USERNAME: this.organization.organization_env?.services_credentials?.NEXT_CLOUD_USERNAME || '',
          NEXT_CLOUD_PASSWORD: this.organization.organization_env?.services_credentials?.NEXT_CLOUD_PASSWORD || '',
          MAILSERVER_POSTMASTER_USERNAME: this.organization.organization_env?.services_credentials?.MAILSERVER_POSTMASTER_USERNAME || '',
          MAILSERVER_POSTMASTER_PASSWORD: this.organization.organization_env?.services_credentials?.MAILSERVER_POSTMASTER_PASSWORD || '',
          KC_SERVICE_ADMIN_USERNAME: this.organization.organization_env?.services_credentials?.KC_SERVICE_ADMIN_USERNAME || '',
          KC_SERVICE_ADMIN_PASSWORD: this.organization.organization_env?.services_credentials?.KC_SERVICE_ADMIN_PASSWORD || '',
          HOST_DATA_SSH_PORT: this.organization.organization_env?.HOST_DATA_SSH_PORT || '',
          SFTP_HOST: this.organization.organization_env?.SFTP_HOST || '',
          SFTP_PORT: this.organization.organization_env?.SFTP_PORT || '',
          SFTP_USERNAME: this.organization.organization_env?.SFTP_USERNAME || '',
          SFTP_PASSWORD: this.organization.organization_env?.SFTP_PASSWORD || '',
          KC_SERVICE_ADMIN_CLIENT_ID: this.organization.organization_env?.KC_SERVICE_ADMIN_CLIENT_ID || '',
          KC_SERVICE_URL: this.organization.organization_env?.KC_SERVICE_URL || '',
          ORG_DOMAIN: this.organization.organization_env?.ORG_DOMAIN || '',
          K8S_PROVIDER: this.organization.organization_env?.K8S_PROVIDER || '',
          ORG_NAMESPACE: this.organization.organization_env?.ORG_NAMESPACE || '',
        })
      }
    }
    // Écouter les changements sur le champ "name"
    this.form.get('name').valueChanges.subscribe((value) => {
      if (value) {
        // Convertir en minuscules et remplacer les espaces par des tirets
        const domain = value.toLowerCase().replace(/\s+/g, '-'); 
        this.form.patchValue({
          ORG_DOMAIN: `${domain}.krisalee.fr`,
          auth: `https://auth.${domain}.krisalee.fr`,
          mail: `https://webmail.${domain}.krisalee.fr`,
          drive: `https://drive.${domain}.krisalee.fr`,
          website: `https://website.${domain}.krisalee.fr`,
          chat: `https://chat.${domain}.krisalee.fr`,
          synapse: `https://synapse.${domain}.krisalee.fr`,
          onlyoffice: `https://onlyoffice.${domain}.krisalee.fr`,
        });
      }
    });

    // initialize the form of services
    if(this.products){
      this.products = this.products.filter(
        (product) => product.service_id !== 'frontal',
      );
      const formArray = this.servicesForm.get('services_to_launch') as FormArray;
      for(let product of this.products){
        formArray.push(new FormGroup({
          product: new FormControl(product),
          checked: new FormControl(true),
          version_id: new FormControl(product.versions[product.versions?.length-1]?.version_id),
          depends_on: new FormControl([]),

        }));
      }
      this.isFormControlVisible = true;
    }
  }

  getFilteredServices(index: number): any[] {
    return this.services.filter(s => s.service_id !== this.products[index].service_id);
  }


  submit(){
    if(this.form.valid){
      this.ref.close(this.form.value);
    }
    else {
      this.ref.close()
    }
  }

  cancel(){
    this.ref.close();
  }

validateStepOne(): void {
  if (this.form.invalid) {
    this.forceShowErrors = true;
  } else {
    this.forceShowErrors = false;
    let dialogRef = this.showExexutingOperationModal(`Création de votre organisation en cours...`);
    const servicesUrl = new ServicesUrl(this.form.get('auth').value, this.form.get('mail').value, this.form.get('drive').value, this.form.get('onlyoffice').value,
           this.form.get('website').value, this.form.get('chat').value, this.form.get('synapse').value);
      const servicesCrendentials = new ServicesCredentials(this.form.get('NEXT_CLOUD_USERNAME').value,
                                                            this.form.get('NEXT_CLOUD_PASSWORD').value,
                                                            this.form.get('MAILSERVER_POSTMASTER_USERNAME').value,
                                                            this.form.get('MAILSERVER_POSTMASTER_PASSWORD').value,
                                                            this.form.get('KC_SERVICE_ADMIN_USERNAME').value,
                                                            this.form.get('KC_SERVICE_ADMIN_PASSWORD').value
                                                          );
      const organizationEnv = new OrganizationEnv(servicesUrl, servicesCrendentials,
                                                  parseInt(this.form.get('HOST_DATA_SSH_PORT').value),
                                                  this.form.get('SFTP_HOST').value,
                                                  parseInt(this.form.get('SFTP_PORT').value),
                                                  this.form.get('SFTP_USERNAME').value,
                                                  this.form.get('SFTP_PASSWORD').value,
                                                  this.form.get('KC_SERVICE_ADMIN_CLIENT_ID').value,
                                                  this.form.get('KC_SERVICE_URL').value,
                                                  this.form.get('ORG_DOMAIN').value,
                                                  this.form.get('K8S_PROVIDER').value,
                                                  this.form.get('ORG_NAMESPACE').value,
                                                );
      const organization = new Organization(this.form.get('name').value, false, organizationEnv);
      //envoie de la requete pour l'ajout d'une nouvelle organisation*
      this.organizationService.addOrganization(organization).subscribe((response) =>{
        // this.organizations.push(response);
        this.organization = response;
        dialogRef.close();
        this.successHandler.handleSuccess(`L'organisation ${organization.name} est ajouté avec succès`);
        this.stepper.next();
      }, error =>{
        dialogRef.close();
        this.errorHandler.handleError(error);
      })
  }
}

validateSecondStep(): void {
  let dialog = this.showExexutingOperationModal(`Vérification en cours...`);
  try {
    this.organizationService.checkK8sAuthfileCreated(this.organization.organization_id).subscribe(response =>{
        if (response) {
          this.organization = response;
          dialog.close();
          this.toastrService.success('Le fichier existe.', 'Succès');
          this.stepper.next();
        } else {
          dialog.close();
          this.toastrService.warning('Le fichier est introuvable.', 'Attention');
        }
      });
  } catch (error) {
    dialog.close();
    this.errorHandler.handleError(error)
  }
}

  isStepValid(step: number): boolean {
    switch (step) {
      case 1:
        return this.form.valid;
      case 2:
        return this.form.get('NEXT_CLOUD_USERNAME')?.valid && this.form.get('NEXT_CLOUD_PASSWORD')?.valid;
      // Ajoutez d'autres étapes si nécessaire
      default:
        return true;
    }
  }

  saveDraft(){
    console.log("save draft")
  }

  checkSnapshots(){
    console.log("vérification des sauvegardes")
  }

  uploadKubeConfigFile(){
      this.dialogService.open(FileUploadModalComponent, {
        context:{
          message : 'Veuillez sélectionner un fichier yml à partir de votre ordinateur.',
          acceptType: '.yaml,.yml'
        }
      })
      .onClose.subscribe((file) => {
        if(file){
          let dialogRef = this.showExexutingOperationModal(`Envoie du fichier en cours ...`);
          this.organizationService.uploadKubeConfigFile(this.organization.organization_id, file).subscribe((response) =>{
            dialogRef.close();
            this.successHandler.handleSuccess('Opération terminée avec succès.')
          }, error =>{
            dialogRef.close();
            this.errorHandler.handleError(error)
          });
        }
      })
    }

  generateLoadBalancerFiles(){
    if(this.organization){
      let dialogRef = this.showExexutingOperationModal(`Génération des fichiers load balancers...`);
      this.organizationService.generateLoadBalancerFiles(this.organization.organization_id).subscribe(response =>{
        dialogRef.close();
        this.successHandler.handleSuccess(`Fichiers générés avec succès sous ${response}`);
      }, error => {
        this.errorHandler.handleError(`Error de génération des fichiers : ${error}`)
        dialogRef.close();
      })
    } else {
      this.errorHandler.handleError(`L'objet organisation est vide ou inexistant`);
    }
  }

  validateLoadBalancerStep(): void {
    if (this.LoadBalancersForm.invalid) {
      this.forceShowErrorsForLoadBalancersForm = true;
    } else {
      this.forceShowErrorsForLoadBalancersForm = false;
      let dialogRef = this.showExexutingOperationModal(`Sauvegarde des adresses IP en cours...`);
      // prepare dto
      let storeIpAddressesDto = new storeIpAddressesDTO();
      storeIpAddressesDto = {
        globalIpAddress : this.LoadBalancersForm.get('globalIpAddress').value,
        loadBalancerIpAddress : this.LoadBalancersForm.get('loadBalancerIpAddress').value,
        servicesGatewayIpAddress : this.LoadBalancersForm.get('servicesGatewayIpAddress').value,
      }
      this.organizationService.storeIpAddresses(this.organization.organization_id, storeIpAddressesDto).subscribe(response => {
        this.organization = response;
        dialogRef.close();
        this.stepper.next();
      }, error =>{
        dialogRef.close();
        this.errorHandler.handleError(error);
      }
      )
    }
  }

    launchMailServerForDkimCertifs(){

    }

  closeParentDialog() {
    if (this.ref) {
      this.ref.close();
    }
  }

  /**
   *   a function that opens a pop-up (loader) when an operation is executing
   */
  showExexutingOperationModal(message: string){
    console.log(this.ref)
    return this.dialogService.open(ExecutingRequestComponent, {
      context: {
        message,
        parentDialogRef: this.ref,
        showCloseButton: true
      },
      closeOnBackdropClick: false,
    });
  }

  checkDnsConfiguration(){
    this.organizationService.checkDnsConfiguration(this.organization.organization_id).subscribe(response =>{
      this.organization = response;
    });
  }


  setupFrontalService(){
    const setupServicesDto = new setupServicesDTO();
    setupServicesDto.services.push(
      {
        service_id: this.frontalService.service_id,
        product_id: this.frontalService.product_id,
        version_id: this.frontalServiceForm.controls.version_id.value,
        depends_on: this.frontalServiceForm.controls.depends_on.value,
      }
    )
    const version_id = this.frontalServiceForm.controls.version_id.value;
    let dialogRef = this.showExexutingOperationModal(`Initialisation du service frontal en cours...`);
    this.organizationService.setupFrontalService(this.organization.organization_id, setupServicesDto).subscribe(response =>{
    dialogRef.close();
    this.successHandler.handleSuccess('Opération terminée avec succès.')
    }, error =>{
      dialogRef.close();
      this.errorHandler.handleError(error)
    });
  }

  ValidateAutoSignedCertsStep(){
    this.organizationService.ValidateAutoSignedCertsStep(this.organization.organization_id).subscribe(response =>{
      this.organization = response;
    });
  }

  setupServices(){
    const setupServicesDto = new setupServicesDTO();
    // retrieve the services from the form
    let servicesFromForm = this.servicesForm.controls.services_to_launch.value;

    // here we will filter(remove) the services that that are not checked in the form
    servicesFromForm = servicesFromForm.filter(serviceFromForm => serviceFromForm.checked !==false);
    for(let serviceFromForm of servicesFromForm){
      setupServicesDto.services.push(
        { service_id : serviceFromForm.product.service_id,
          version_id: serviceFromForm.version_id,
          product_id: serviceFromForm.product.product_id,
          depends_on: serviceFromForm.depends_on,
        }
      );
    }
    let dialogRef = this.showExexutingOperationModal(`Initialisation des services en cours...`);
    this.organizationService.setupServices(this.organization.organization_id, setupServicesDto).subscribe(response =>{
    dialogRef.close();
    this.successHandler.handleSuccess('Opération terminée avec succès.')
    }, error =>{
      dialogRef.close();
      this.errorHandler.handleError(error)
    });
  }
}
