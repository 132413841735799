<nb-card>
  <nb-card-header class="title">
    <div class="header-content">
      <span *ngIf="editMode">{{(editMode && user) ? "Modifier un utilisateur" : "Ajouter un utilisateur" }}</span>
      <span *ngIf="!editMode">Détails d'utilisateur</span>
    </div>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="userForm">
      <div class="profile-columns">
        <div class="column">
          <div class="profile-row">
            <nb-icon icon="person-outline"></nb-icon>
            <div class="name-inputs">
              <ng-container *ngIf="!editMode">
                <span>{{ user?.firstName }} {{ user?.lastName }}</span>
              </ng-container>
              <ng-container *ngIf="editMode">
                <input class="prenom" nbInput formControlName="firstName" placeholder="Prénom">
                <input class="nom" nbInput formControlName="lastName" placeholder="Nom">
              </ng-container>
            </div>
          </div>
          <div class="profile-row">
            <nb-icon icon="globe-outline"></nb-icon>
            <span *ngIf="!editMode">{{ user?.jobDepartment }}</span>
            <input *ngIf="editMode" nbInput formControlName="jobDepartment" placeholder="Département">
          </div>
          <div class="profile-row" *ngIf="!editMode && user">
            <nb-icon icon="email-outline"></nb-icon>
            <span>{{ user?.email }}</span>
          </div>
          <div class="profile-row">
            <nb-icon icon="alert-triangle-outline"></nb-icon>
            <span *ngIf="!editMode">Niveau : {{ user?.alert_level == 1 ? 'Exercice' : user?.alert_level }}</span>
            <nb-select *ngIf="editMode" fullWidth formControlName="alert_level" placeholder="Niveau">
              <nb-option value="0">0</nb-option>
              <nb-option value="1">Exercice</nb-option>
              <nb-option value="2">2</nb-option>
              <nb-option value="3">3</nb-option>
            </nb-select>
          </div>
          <div class="profile-row">
            <nb-icon icon="calendar-outline"></nb-icon>
            <span *ngIf="!editMode">{{ user?.personalDate }}</span>
            <ng-container *ngIf="editMode">
              <div class="edit-mode-container">
                <div class="input-wrapper">
                  <input nbInput placeholder="Date personnelle" [nbDatepicker]="datepicker" formControlName="personalDate" />
                  <nb-datepicker #datepicker></nb-datepicker>
                </div>
                <div *ngIf="userForm?.get('personalDate')?.invalid && userForm?.get('personalDate')?.touched" class="error-container">
                  <small *ngIf="userForm.get('personalDate')?.errors" class="text-danger">
                    Respectez le format AAAA-MM-JJ.
                  </small>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="column">
          <div class="profile-row">
            <nb-icon icon="person-done-outline" *ngIf="(editMode && !user) || !editMode"></nb-icon>
            <span *ngIf="!editMode">{{ user?.username }}</span>
            <input *ngIf="editMode && !user" nbInput formControlName="username" placeholder="Nom d'utilisateur">
          </div>
          <div class="profile-row">
            <nb-icon icon="award-outline"></nb-icon>
            <span *ngIf="!editMode">{{ user?.jobTitle }}</span>
            <input *ngIf="editMode" nbInput formControlName="jobTitle" placeholder="Poste">
          </div>
          <div class="profile-row">
            <nb-icon icon="phone-outline"></nb-icon>
            <span *ngIf="!editMode">{{ user?.phoneNumber }}</span>
            <input *ngIf="editMode" nbInput formControlName="phoneNumber" placeholder="Téléphone">
          </div>
          <div class="profile-row" *ngIf="!editMode">
            <nb-icon
              [icon]="user?.alerted ? 'checkmark-circle-outline' : user?.alertedWithError ? 'alert-circle-outline' : 'clock-outline'"
              [status]="user?.alerted ? 'success' : user?.alertedWithError ? 'danger' : 'warning'"></nb-icon>
            <span>
              {{ user?.alerted ? 'Utilisateur notifié' : user?.alertedWithError ? 'Erreur, alerte mal envoyée' : 'Utilisateur pas encore notifié' }}
            </span>
          </div>
          <div class="profile-row">
            <nb-icon icon="shield-outline"></nb-icon>
            <span *ngIf="!editMode && user">{{ user?.admin ? 'Admin' : 'Non Admin' }}</span>
            <nb-toggle *ngIf="editMode || !user" formControlName="admin">Admin</nb-toggle>
          </div>
        </div>
      </div>
      <div class="button-container">
        <div class="button-group">
          <button *ngIf="!editMode" nbButton (click)="showAlert(user)">Notifier</button>
        </div>
        <div class="button-group">
          <ng-container *ngIf="!editMode && user">
            <button nbButton status="info" (click)="toggleEdit()">Modifier</button>
            <button nbButton status="danger" (click)="confirmDelete(user)">Supprimer</button>
          </ng-container>
          <ng-container *ngIf="editMode && user">
            <button nbButton status="basic" (click)="cancelEdit()">Annuler</button>
            <button nbButton status="success" [disabled]="userForm.invalid || !userHasChanged()" (click)="saveChanges()">Sauvegarder</button>
          </ng-container>
          <ng-container *ngIf="editMode && !user">
            <button nbButton status="basic" (click)="close()">Annuler</button>
            <button nbButton status="success" [disabled]="userForm.invalid" (click)="saveChanges()">Sauvegarder</button>
          </ng-container>
        </div>
      </div>
    </form>
  </nb-card-body>
</nb-card>
