import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UpdateConnectorFormComponent} from "./update-connector-form.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NbInputModule, NbSelectModule} from "@nebular/theme";



@NgModule({
  declarations: [UpdateConnectorFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    NbInputModule,
    ReactiveFormsModule,
    NbSelectModule,
  ]
})
export class UpdateConnectorFormModule { }
