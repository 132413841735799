<div class="container">
    <p><strong>{{message}}</strong></p>
    <form [formGroup]="uploadFileForm" (submit)="submit()" class="form">
        <input id="file-upload" type="file" class="file-upload" accept="{{acceptType}}" 
            (change)="onFileChange($event.target.files)" formControlName="file">
        <div class="form-footer">
            <button id="cancel-button" class="cancel-button" (click)="cancel()">Annuler</button>        
            <button id="confirm-button" class="confirm-button" (click)="submit()" 
            [disabled]="uploadFileForm.invalid">Envoyer
            </button>
        </div>
    </form>
</div>