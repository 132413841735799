export enum OrganizationCreationEnum {
    CREATING = 0,
    CREATED = 1,
    ERROR = 2
  }

export enum OrganizationCreationStep {
    FIRST_STEP = 1,
    SECOND_STEP = 2,
    THIRD_STEP = 3,
    FOURTH_STEP = 4,
    FIFTH_STEP = 5,
    SIXTH_STEP = 6,
    SEVENTH_STEP = 7
}