import {Component, Input} from '@angular/core';
import {NbDialogRef} from "@nebular/theme";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'ngx-update-connector-form',
  templateUrl: './update-connector-form.component.html',
  styleUrl: './update-connector-form.component.scss'
})
export class UpdateConnectorFormComponent {
  @Input() connector: any;
  @Input() services: any;
  @Input() organizations: any;
  public constructor(protected ref: NbDialogRef<UpdateConnectorFormComponent>){}

  form = new FormGroup({
    connector_id: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    depends_on: new FormControl([]),
    organizations: new FormControl([]),
  });

  ngOnInit(){
    if(this.connector){
      this.form.patchValue({
        connector_id: this.connector.connector_id || '',
        name: this.connector.name || '',
        description: this.connector.description || '',
        depends_on: this.connector.depends_on || [],
        organizations: this.connector.organizations.map(org => org.organization_id) || []
      })
    }
  }

  submit() {
    if (this.form.valid) {
      const updatedConnector = this.form.value;
      this.ref.close(updatedConnector);
    } else {
      this.ref.close();
    }
  }

  cancel(){
    this.ref.close();
  }

}
