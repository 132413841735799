import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbInputModule, NbSelectModule } from '@nebular/theme';
import { UpdateProductFormComponent } from './update-product-form.component';


@NgModule({
  declarations: [
    UpdateProductFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NbInputModule,
    NbSelectModule,
  ],
  exports:[
    UpdateProductFormComponent,
  ]
})
export class UpdateProductFormModule { }
