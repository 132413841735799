import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private apiUrl = environment.API_URL;

  constructor(private http: HttpClient) { }

  getUsers(organization_id: string) {
    try {
      return this.http.get(this.apiUrl + '/organization/' + organization_id + '/user/',);
    } catch (error) {
      throw new Error(error);
    }
  }

  alertUser(organization_id: string, user_id: string){
    try {
      return this.http.post(this.apiUrl + '/organization/' + organization_id + '/user/' + user_id +'/alert',{

      });
    } catch (error) {
      throw new Error(error);
    }
  }

  deleteUser(organization_id: string, user_id: string) {
    try {
      return this.http.delete(this.apiUrl + '/organization/' + organization_id + '/user/' + user_id);
    } catch (error){
      throw new Error(error);
    }
  }

  resetUserTries(organization_id: string, user_id:string){
    try {
      return this.http.post(this.apiUrl + '/organization/' + organization_id + '/user/' + user_id +'/reset',{
      });
    } catch (error) {
      throw new Error(error);
    }
  }

  updateUser(organization_id: string, id: string,updateUserDto: any){
    try {
      // The user id remains unchanged (keycloak object id)
      return this.http.patch(this.apiUrl + '/organization/' + organization_id + '/user/' + id, updateUserDto);
    } catch (error) {
      throw new Error(error);
    }
  }

  addUser(organization_id: string, user: any){
    try {
      return this.http.post(this.apiUrl + '/organization/' + organization_id + '/user', user);
    } catch (error) {
      throw new Error(error);
    }
  }

  // resetAllUsersTries(organization_id: string){
  //   try {
  //     return this.http.post(this.apiUrl + '/organization/' + organization_id + '/user' + '/reset',{
  //     });
  //   } catch (error) {
  //     throw new Error(error);
  //   }
  // }
}
