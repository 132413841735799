<div class="container">
    <nb-card class="card">
        <nb-card-header>
            <h5 class="title">Ajout d'une nouvelle organisation</h5>
        </nb-card-header>
        <nb-card-body>
            <nb-stepper orientation="horizontal" disableStepNavigation #stepper [linear]="linearMode" [selectedIndex]="organization ? organization.creationStatus.step : 0">
                <!-- Étape 1 : Infos du client -->
                <nb-step [label]="clientInfos" [stepControl]="form">
                    <form [formGroup]="form">
                        <ng-template #clientInfos>Infos du client</ng-template>
                            <!-- Informations de l'organisation -->
                            <h6>Informations de l'organisation</h6>
                            <hr>
                            <label>
                                <strong>Nom<span style="color: red">*</span> :</strong>
                                <small class="text-danger" *ngIf="form.get('name')?.invalid && (form.get('name')?.touched || forceShowErrors)">
                                    Le nom est requis et doit contenir au moins 3 caractères.
                                </small>
                                <input type="text" nbInput fullWidth shape="semi-round" placeholder="Veuillez saisir le nom de votre organisation" formControlName="name"/>
                              </label>
                            <br>
                            <label for="ORG_DOMAIN">
                                <strong>Nom de domaine de l'organisation<span style="color: red">*</span> :</strong>
                                <small class="text-danger" *ngIf="form.get('ORG_DOMAIN')?.invalid && (form.get('ORG_DOMAIN')?.touched || forceShowErrors)">
                                    Le nom de domaine de votre organisation est invalide.
                                </small>
                                <input nbInput fullWidth shape="semi-round" placeholder="Exemple: krisalee.joona.fr" id="ORG_DOMAIN" formControlName="ORG_DOMAIN">
                            </label>
                            <br>
                            <label>
                                <strong>Provider kubernetes<span style="color: red">*</span> :</strong>
                                <small class="text-danger" *ngIf="form.get('K8S_PROVIDER')?.invalid && (form.get('K8S_PROVIDER')?.touched || forceShowErrors)">
                                    Le provider kubernetes est invalide.
                                </small>
                                <nb-select status="primary" placeholder="Veuillez sélectionner le provider kubernetes" formControlName="K8S_PROVIDER" fullWidth>
                                  <nb-option *ngFor="let provider of k8sProviders" [value]="provider">{{provider}}</nb-option>
                                </nb-select>
                            </label>
                            <br>
                            <label for="ORG_NAMESPACE">
                                <strong>Espace de nom (namespace) au sein du cluster kubernetes<span style="color: red">*</span> :</strong>
                                <small class="text-danger" *ngIf="form.get('ORG_NAMESPACE')?.invalid && (form.get('ORG_NAMESPACE')?.touched || forceShowErrors)">
                                    Le namespace de votre organisation est invalide.
                                </small>
                                <input nbInput fullWidth shape="semi-round" placeholder="Exemple: demo-krisalee" id="ORG_NAMESPACE" formControlName="ORG_NAMESPACE">
                            </label>
                            <br>
                            <!-- <strong>Voulez-vous donner les privilèges d'administration à cet organisation ? <span style="color: red">*</span></strong>
                            <label for="is_root_true">
                                    <input type="radio" id="is_root_true" formControlName="is_root" [value]="true" class="custom-radio-input"> Oui
                            </label>
                            <label for="is_root_false">
                                    <input type="radio" id="is_root_false" formControlName="is_root" [value]="false" class="custom-radio-input"> Non
                            </label>
                            <br> -->

                                <!-- URLs des services -->
                            <h6>URLs d'accès aux services de votre organisation</h6>
                            <hr>
                            <label for="auth"><strong>Service d'authentification<span style="color: red">*</span> :</strong>
                                <small class="text-danger" *ngIf="form.get('auth')?.invalid && (form.get('auth')?.touched || forceShowErrors)">
                                    URL invalide (exemple : https://exemple.com).
                                </small>
                                <input nbInput fullWidth shape="round" placeholder="{{servicesUrlsPlaceholder}}" id="auth" formControlName="auth">
                            </label>
                            <br>
                            <label for="mail"><strong>Service mail<span style="color: red">*</span> :</strong>
                                <small class="text-danger" *ngIf="form.get('mail')?.invalid && (form.get('mail')?.touched || forceShowErrors)">
                                    URL invalide (exemple : https://exemple.com).
                                </small>
                                <input nbInput fullWidth shape="round" placeholder="{{servicesUrlsPlaceholder}}" id="mail" formControlName="mail">
                            </label>
                            <br>
                            <label for="drive"><strong>Service de partage de documents<span style="color: red">*</span> :</strong>
                                <small class="text-danger" *ngIf="form.get('drive')?.invalid && (form.get('drive')?.touched || forceShowErrors)">
                                    URL invalide (exemple : https://exemple.com).
                                </small>
                                <input nbInput fullWidth shape="round" placeholder="{{servicesUrlsPlaceholder}}" id="drive" formControlName="drive">
                            </label>
                            <br>
                            <label for="website"><strong>Service du site interne<span style="color: red">*</span> :</strong>
                                <small class="text-danger" *ngIf="form.get('website')?.invalid && (form.get('website')?.touched || forceShowErrors)">
                                    URL invalide (exemple : https://exemple.com).
                                </small>
                                <input nbInput fullWidth shape="round" placeholder="{{servicesUrlsPlaceholder}}" id="website" formControlName="website">
                            </label>
                            <br>
                            <label for="chat"><strong>Service de messagerie instantanée<span style="color: red">*</span> :</strong>
                                <small class="text-danger" *ngIf="form.get('chat')?.invalid && (form.get('chat')?.touched || forceShowErrors)">
                                    URL invalide (exemple : https://exemple.com).
                                </small>
                                <input nbInput fullWidth shape="round" placeholder="{{servicesUrlsPlaceholder}}" id="chat" formControlName="chat">
                            </label>
                            <br>
                            <label for="synapse"><strong>Service synapse<span style="color: red">*</span> :</strong>
                                <small class="text-danger" *ngIf="form.get('synapse')?.invalid && (form.get('synapse')?.touched || forceShowErrors)">
                                    URL invalide (exemple : https://exemple.com).
                                </small>
                                <input nbInput fullWidth shape="round" placeholder="{{servicesUrlsPlaceholder}}" id="synapse" formControlName="synapse">
                            </label>
                            <br>
                            <label for="onlyoffice"><strong>Service OnlyOffice<span style="color: red">*</span> :</strong>
                                <small class="text-danger" *ngIf="form.get('onlyoffice')?.invalid && (form.get('onlyoffice')?.touched || forceShowErrors)">
                                    URL invalide (exemple : https://exemple.com).
                                </small>
                                <input nbInput fullWidth shape="round" placeholder="{{servicesUrlsPlaceholder}}" id="onlyoffice" formControlName="onlyoffice">
                            </label>
                            <br>

                            <!-- section pour la définition des identifiants d'accès au services -->
                            <h6>Identifiants d'accès aux services de votre organisation</h6>
                            <hr>

                            <label for="NEXT_CLOUD_USERNAME"><strong>Nom d'utilisateur du service de partage de documents<span style="color: red">*</span> :</strong>
                                <small class="text-danger" *ngIf="form.get('NEXT_CLOUD_USERNAME')?.invalid && (form.get('NEXT_CLOUD_USERNAME')?.touched || forceShowErrors)">
                                    Nom d'utilisateur invalide.
                                </small>
                                <input nbInput fullWidth shape="round" placeholder="Username" id="NEXT_CLOUD_USERNAME" formControlName="NEXT_CLOUD_USERNAME">
                            </label>
                            <br>
                            <label for="NEXT_CLOUD_PASSWORD"><strong>Mot de passe du service de partage de documents<span style="color: red">*</span> :</strong>
                                <small class="text-danger" *ngIf="form.get('NEXT_CLOUD_PASSWORD')?.invalid && (form.get('NEXT_CLOUD_PASSWORD')?.touched || forceShowErrors)">
                                    Champs invalide.
                                </small>
                                <input type="password" nbInput fullWidth shape="round" placeholder="**********" id="NEXT_CLOUD_PASSWORD" formControlName="NEXT_CLOUD_PASSWORD">
                            </label>
                            <br>
                            <label for="MAILSERVER_POSTMASTER_USERNAME"><strong>Nom d'utilisateur du service mail (admin master)<span style="color: red">*</span> :</strong>
                                <small class="text-danger" *ngIf="form.get('MAILSERVER_POSTMASTER_USERNAME')?.invalid && (form.get('MAILSERVER_POSTMASTER_USERNAME')?.touched || forceShowErrors)">
                                    Nom d'utilisateur invalide.
                                </small>
                                <input nbInput fullWidth shape="round" placeholder="Username" id="MAILSERVER_POSTMASTER_USERNAME" formControlName="MAILSERVER_POSTMASTER_USERNAME">
                            </label>
                            <br>
                            <label for="MAILSERVER_POSTMASTER_PASSWORD"><strong>Mot de passe du service mail (admin master)<span style="color: red">*</span> :</strong>
                                <small class="text-danger" *ngIf="form.get('MAILSERVER_POSTMASTER_PASSWORD')?.invalid && (form.get('MAILSERVER_POSTMASTER_PASSWORD')?.touched || forceShowErrors)">
                                    Champs invalide.
                                </small>
                                <input type="password" nbInput fullWidth shape="round" placeholder="**********" id="MAILSERVER_POSTMASTER_PASSWORD" formControlName="MAILSERVER_POSTMASTER_PASSWORD">
                            </label>
                            <br>

                            <!-- Autres paramètres -->
                            <h5>Autres informations</h5>
                            <hr>
                            <label for="HOST_DATA_SSH_PORT"><strong>Port SSH utilisé<span style="color: red">*</span> :</strong>
                                <small class="text-danger" *ngIf="form.get('HOST_DATA_SSH_PORT')?.invalid && (form.get('HOST_DATA_SSH_PORT')?.touched || forceShowErrors)">
                                    Champs invalide.
                                </small>
                                <input type="number" nbInput fullWidth shape="round" placeholder="Exemple: 22" id="HOST_DATA_SSH_PORT" formControlName="HOST_DATA_SSH_PORT">
                            </label>
                            <br>
                            <label for="SFTP_HOST"><strong>Adresse du serveur SFTP<span style="color: red">*</span> :</strong>
                                <small class="text-danger" *ngIf="form.get('SFTP_HOST')?.invalid && (form.get('SFTP_HOST')?.touched || forceShowErrors)">
                                    Champs invalide.
                                </small>
                                <input nbInput fullWidth shape="semi-round" placeholder="Saisissez l'adresse de votre serveur SFTP" id="SFTP_HOST" formControlName="SFTP_HOST">
                            </label>
                            <br>
                            <label for="SFTP_PORT"><strong>Port SFTP<span style="color: red">*</span> :</strong>
                                <small class="text-danger" *ngIf="form.get('SFTP_PORT')?.invalid && (form.get('SFTP_PORT')?.touched || forceShowErrors)">
                                    Champs invalide.
                                </small>
                                <input type="number" nbInput fullWidth shape="semi-round" placeholder="Exemple : 2240" id="SFTP_PORT" formControlName="SFTP_PORT">
                            </label>
                            <br>
                            <label for="SFTP_USERNAME"><strong>Nom d'utilisateur SFTP<span style="color: red">*</span> :</strong>
                                <small class="text-danger" *ngIf="form.get('SFTP_USERNAME')?.invalid && (form.get('SFTP_USERNAME')?.touched || forceShowErrors)">
                                    Nom d'utilisateur invalide.
                                </small>
                                <input nbInput fullWidth shape="semi-round" placeholder="Username" id="SFTP_USERNAME" formControlName="SFTP_USERNAME">
                            </label>
                            <br>
                            <label for="SFTP_PASSWORD"><strong>Mot de passe<span style="color: red">*</span> :</strong>
                                <small class="text-danger" *ngIf="form.get('SFTP_PASSWORD')?.invalid && (form.get('SFTP_PASSWORD')?.touched || forceShowErrors)">
                                    Champs invalide.
                                </small>
                                <input type="password" nbInput fullWidth shape="semi-round" placeholder="**********" id="SFTP_PASSWORD" formControlName="SFTP_PASSWORD">
                            </label>
                            <br>

                            <!-- Informations keycloak admin -->
                            <h6>Informations du service Keycloak</h6>
                            <hr>

                            <label for="KC_SERVICE_ADMIN_CLIENT_ID"><strong>Identifiant du client<span style="color: red">*</span>  : ?</strong>
                                <small class="text-danger" *ngIf="form.get('KC_SERVICE_ADMIN_CLIENT_ID')?.invalid && (form.get('KC_SERVICE_ADMIN_CLIENT_ID')?.touched || forceShowErrors)">
                                    Champs invalide.
                                </small>
                                <input nbInput fullWidth shape="semi-round" placeholder="id_client" id="KC_SERVICE_ADMIN_CLIENT_ID" formControlName="KC_SERVICE_ADMIN_CLIENT_ID">
                            </label>
                            <br>
                            <label for="KC_SERVICE_URL"><strong>URL du service Keycloak<span style="color: red">*</span> :</strong>
                                <small class="text-danger" *ngIf="form.get('KC_SERVICE_URL')?.invalid && (form.get('KC_SERVICE_URL')?.touched || forceShowErrors)">
                                    Champs invalide.
                                </small>
                                <input nbInput fullWidth shape="semi-round" placeholder="{{servicesUrlsPlaceholder}}" id="KC_SERVICE_URL" formControlName="KC_SERVICE_URL">
                            </label>
                            <br>
                            <label for="KC_SERVICE_ADMIN_USERNAME"><strong>Nom d'utilisateur du service Keycloak<span style="color: red">*</span> :</strong>
                                <small class="text-danger" *ngIf="form.get('KC_SERVICE_ADMIN_USERNAME')?.invalid && (form.get('KC_SERVICE_ADMIN_USERNAME')?.touched || forceShowErrors)">
                                    Nom d'utilisateur invalide.
                                </small>
                                <input nbInput fullWidth shape="round" placeholder="Username" id="KC_SERVICE_ADMIN_USERNAME" formControlName="KC_SERVICE_ADMIN_USERNAME">
                            </label>
                            <br>
                            <label for="KC_SERVICE_ADMIN_PASSWORD"><strong>Mot de passe Keycloak<span style="color: red">*</span> :</strong>
                                <small class="text-danger" *ngIf="form.get('KC_SERVICE_ADMIN_PASSWORD')?.invalid && (form.get('KC_SERVICE_ADMIN_PASSWORD')?.touched || forceShowErrors)">
                                    Champs invalide.
                                </small>
                                <input type="password" nbInput fullWidth shape="round" placeholder="**********" id="KC_SERVICE_ADMIN_PASSWORD" formControlName="KC_SERVICE_ADMIN_PASSWORD">
                            </label>
                            <br>
                            <div class="step-buttons">
                            <!-- <button nbButton status="info" (click)="saveDraft()">Enregistrer</button> -->
                            <button nbButton (click)="validateStepOne()">Créer et poursuivre</button>
                            </div>
                        </form>
                </nb-step>

                <!-- Étape 2 : chargement du fichier kubeConfig -->
                <nb-step [label]="kubeConfigFile">
                    <ng-template #kubeConfigFile>fichier kubeConfig</ng-template>
                    <h6>Vérification d'existence du fichier d'authentification k8s (kubeconfig.yaml)</h6>
                    <hr>
                    <button nbButton class="pointer"
                        (click)="uploadKubeConfigFile()" title="Sélectionner le fichier de connexion au cluster kubernetes">
                        Charger fichier <i class="fa fa-upload" style="margin-left: 0.5rem;"></i>
                    </button>
                    <div class="step-buttons">
                        <button nbButton class="pointer" (click)="validateSecondStep()">
                            Vérifier l'ajout du fichier et poursuivre
                        </button>
                    </div>
                </nb-step>

                <!-- Étape 3 : Préparation des load balancers -->
                <nb-step [label]="loadBalancersStep">
                    <ng-template #loadBalancersStep>Load balancers</ng-template>
                    <h6>Lancement de load balancers pour allocation d'adresses IP publiques</h6>
                    <hr>
                    <strong class="text-danger">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        Les adresses IP demandées sont obtenues en lançant les fichiers des load balancers.
                    </strong>
                    <br>
                    <div *ngIf="organization">
                        <p>Chemin des fichiers : <code>/var/lib/docker/volumes/krisalee-admin-back_data-clients/_data/{{organization.organization_id}}/k8s/load-balancer</code></p>
                    </div>
                    <div>
                        <span>Fichiers introuvables ? </span>
                        <button nbButton class="pointer" size="small"
                            (click)="generateLoadBalancerFiles()" title="Sélectionner le fichier de connexion au cluster kubernetes">
                            Générer les fichiers <i class="fa fa-cogs" style="margin-left: 0.5rem;"></i>
                        </button>
                    </div>
                    <br>
                    <form [formGroup]="LoadBalancersForm">
                        <label>
                            <strong>Adresse IP publique globale du load balancer<span style="color: red">*</span> :</strong>
                            <small class="text-danger" *ngIf="LoadBalancersForm.get('globalIpAddress')?.invalid &&
                                (LoadBalancersForm.get('globalIpAddress')?.touched || forceShowErrorsForLoadBalancersForm)">
                                L'adresse ip est requise et doit être une IP valide.
                            </small>
                            <input type="text" nbInput fullWidth shape="semi-round" placeholder=" .  .  .  . " formControlName="globalIpAddress"/>
                            </label>
                        <br>
                        <label>
                            <strong>Adresse IP publique du load balancer de mail<span style="color: red">*</span> :</strong>
                            <small class="text-danger" *ngIf="LoadBalancersForm.get('loadBalancerIpAddress')?.invalid &&
                                (LoadBalancersForm.get('loadBalancerIpAddress')?.touched || forceShowErrorsForLoadBalancersForm)">
                                L'adresse ip est requise et doit être une IP valide.
                            </small>
                            <input type="text" nbInput fullWidth shape="semi-round" placeholder=" .  .  .  . " formControlName="loadBalancerIpAddress"/>
                        </label>
                        <br>
                        <label>
                            <strong>Adresse IP du gateway (IP de sortie des services de survie)<span style="color: red">*</span> :</strong>
                            <small class="text-danger" *ngIf="LoadBalancersForm.get('servicesGatewayIpAddress')?.invalid &&
                                (LoadBalancersForm.get('servicesGatewayIpAddress')?.touched || forceShowErrorsForLoadBalancersForm)">
                                L'adresse ip est requise et doit être une IP valide.
                            </small>
                            <input type="text" nbInput fullWidth shape="semi-round" placeholder=" .  .  .  . " formControlName="servicesGatewayIpAddress"/>
                        </label>
                        <br>
                    </form>
                    <div class="step-buttons">
                        <button nbButton nbStepperPrevious>Précédent</button>
                        <button nbButton (click)="validateLoadBalancerStep()">Suivant</button>
                    </div>
                </nb-step>

                <!-- Étape 4 : Configs dns -->
                <nb-step [label]="DNS">
                    <ng-template #DNS>configuration DNS</ng-template>
                    <h6>Vérification de la configuration DNS</h6>
                    <hr>
                    <strong>
                        Merci de bien ajouter les entrées DNS suivantes chez votre fournisseur DNS :
                    </strong>
                    <br><br>
                    <pre class="bg-dark text-light" *ngIf="organization">
                        <code>
                            gateway.{{organization.organization_id}} 10800 IN A {{organization.organization_env.SERVICES_GATEWAY_IP_ADDRESS}}
                            loadbalancer.{{organization.organization_id}} 10800 IN A {{organization.organization_env.GLOBAL_IP_ADDRESS}}

                            mail.{{organization.organization_id}} 10800 IN A {{organization.organization_env.LOAD_BALANCER_IP_ADDRESS}}
                            {{organization.organization_id}} 10800 IN MX 10 mail.{{organization.organization_id}}
                            {{organization.organization_id}} 10800 IN TXT "v=spf1 a:gateway.{{organization.organization_id}}.krisalee.fr -all"
                            _dmarc.{{organization.organization_id}} 10800 IN TXT "v=DMARC1; p=none; rua={{organization.organization_env.services_credentials.MAILSERVER_POSTMASTER_USERNAME}}"

                            {{organization.organization_id}} 10800 IN A {{organization.organization_env.GLOBAL_IP_ADDRESS}}
                            auth.{{organization.organization_id}} 10800 IN CNAME loadbalancer.{{organization.organization_id}}
                            chat.{{organization.organization_id}} 10800 IN CNAME loadbalancer.{{organization.organization_id}}
                            synapse.{{organization.organization_id}} 10800 IN CNAME loadbalancer.{{organization.organization_id}}
                            drive.{{organization.organization_id}} 10800 IN CNAME loadbalancer.{{organization.organization_id}}
                            onlyoffice.{{organization.organization_id}} 10800 IN CNAME loadbalancer.{{organization.organization_id}}
                            webmail.{{organization.organization_id}} 10800 IN CNAME loadbalancer.{{organization.organization_id}}
                            website.{{organization.organization_id}} 10800 IN CNAME loadbalancer.{{organization.organization_id}}
                        </code>
                    </pre>
                    <nb-checkbox [(ngModel)]="dnsConfigured">
                        J'ai bien configuré les entrées DNS
                    </nb-checkbox>

                    <div class="step-buttons">
                        <button nbButton nbStepperPrevious>Précédent</button>
                        <button nbButton nbStepperNext [disabled]="!dnsConfigured" (click)="checkDnsConfiguration()">Suivant</button>
                    </div>
                </nb-step>

                <!-- Étape 5 : Lancement du service frontal -->
                <nb-step [label]="frontalServiceStep">
                    <ng-template #frontalServiceStep>Service Frontal</ng-template>
                    <h6>Pré-chauffe du service frontal</h6>
                    <hr>
                    <form [formGroup]="frontalServiceForm">
                        <label for="version_id">
                            <strong>
                                Sélectionner une version du service frontal<span style="color: red">*</span> :
                            </strong>
                            <nb-select formControlName="version_id" placeholder="Choisir une version" size="small">
                                <nb-option *ngFor="let version of frontalService?.versions" [value]="version.version_id">
                                {{ version.name }}
                                </nb-option>
                            </nb-select>
                        </label>
                        <div class="frontalFormButton">
                            <button nbButton status="info" (click)="setupFrontalService()" [disabled]="frontalServiceForm.invalid">Lancer le service frontal</button>
                        </div>
                    </form>
                    <div class="step-buttons">
                        <button nbButton nbStepperPrevious>Précédent</button>
                        <button nbButton nbStepperNext>Suivant</button>
                    </div>
                </nb-step>

                <!-- Étape 6 : Initialisation des services -->
                <nb-step [label]="servicesSetup">
                    <ng-template #servicesSetup>Services</ng-template>
                    <h6>Initialisation des services</h6>
                    <hr>
                    <form [formGroup]="servicesForm">
                        <div formArrayName="services_to_launch" *ngIf="isFormControlVisible">
                            <div class="container-fluid">
                                <!-- the following controls here(services_to_launch attribute) stands for the list of formGroups -->
                                <div [formGroupName]="i" *ngFor="let item of servicesForm.controls?.services_to_launch?.controls; let i = index">
                                    <div class="row mb-2 aligncontent">
                                        <div class="col-4">
                                            <nb-checkbox checked formControlName="checked" class="checkbox">{{ products[i].name }} : </nb-checkbox>
                                        </div>
                                        <div class="col-3">
                                            <nb-select formControlName="version_id" placeholder="Choisissez une version" [disabled]="!(item.controls.checked.value)" size="tiny">
                                                <nb-option *ngFor="let version of products[i].versions" [value]="version.version_id">
                                                    {{version.name}}
                                                </nb-option>
                                            </nb-select>
                                        </div>
                                        <div class="col-3">
                                            <nb-select multiple formControlName="depends_on" placeholder="depends_on" [disabled]="!(item.controls.checked.value)"
                                            class="servicesDropdown" size="tiny">
                                                <nb-option *ngFor="let service of getFilteredServices(i)"
                                                     value="{{service.service_id}}">
                                                        {{service.name}}
                                                </nb-option>
                                            </nb-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button nbButton status="info" (click)="setupServices()">Démarrer</button>
                    </form>
                    <div class="step-buttons">
                        <button nbButton nbStepperPrevious>Précédent</button>
                        <button nbButton nbStepperNext>Suivant</button>
                    </div>
                </nb-step>

                <!-- Étape 7 : Génération des certificats autosignés -->
                <nb-step [label]="autosignedCerts">
                    <ng-template #autosignedCerts>Certifs autosignés</ng-template>
                    <h6>Génération des certificats autosignés pour le service mail</h6>
                    <hr>
                    <strong>
                        Merci d'exécuter les commandes suivantes :
                    </strong>
                    <span class="text-danger">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        N'oubliez pas d'adapter ces paramètres à votre configuration spécifique.
                    </span>
                    <br>
                    <br>
                    <pre class="bg-dark text-light" *ngIf="organization">
                        <code>
                            docker run -it --rm --name smallstep -v "$PWD":/app -w /app smallstep/step-cli

                            step certificate create "Smallstep Root CA" "cacert.pem" "cakey.pem" \
                            --no-password --insecure \
                            --profile root-ca \
                            --not-before "2025-01-01T00:00:00+00:00" \
                            --not-after "2035-01-01T00:00:00+00:00" \
                            --san "{{organization.organization_id}}.krisalee.fr" \
                            --san "mail.{{organization.organization_id}}.krisalee.fr" \
                            --kty RSA --size 2048

                            step certificate create "Smallstep Leaf" mail.{{organization.organization_id}}.krisalee.fr-cert.pem mail.{{organization.organization_id}}.krisalee.fr.fr-key.pem \
                            --no-password --insecure \
                            --profile leaf \
                            --ca "cacert.pem" \
                            --ca-key "cakey.pem" \
                            --not-before "2025-01-01T00:00:00+00:00" \
                            --not-after "2035-01-01T00:00:00+00:00" \
                            --san "{{organization.organization_id}}.krisalee.fr" \
                            --san "mail.{{organization.organization_id}}.krisalee.fr" \
                            --kty RSA --size 2048
                        </code>
                    </pre>
                    <br>
                    <p class="text-danger">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        Une fois terminé, Les certificats sont prêts ! Vous les trouverez dans votre répertoire courant. 🚀 N'oubliez pas de charger les clés dans les secrets du service mail.
                    </p>
                    <div *ngIf="organization">
                        <p>Chemin du fichier : <code>/var/lib/docker/volumes/krisalee-admin-back_data-clients/_data/{{organization.organization_id}}/service/mail/mailserver/version/sauvegarde/config/mailserver/secrets.yaml</code></p>
                        <strong><i class="fa fa-hand-point-right"></i> N.B :</strong> Veuillez remplacer 'version' et 'sauvegarde' par les valeurs correspondant à votre environnement.
                    </div>
                    <br>
                    <nb-checkbox [(ngModel)]="certChecked">
                        J'ai bien généré et chargé les certificats
                    </nb-checkbox>

                    <div class="step-buttons">
                        <button nbButton nbStepperPrevious>Précédent</button>
                        <button nbButton nbStepperNext [disabled]="!certChecked" (click)="ValidateAutoSignedCertsStep()">Suivant</button>
                    </div>
                </nb-step>

                <!-- Étape 8 : certifs dkim -->
                <nb-step [label]="DKIMCertificates">
                    <ng-template #DKIMCertificates>Certifs DKIM</ng-template>
                    <h6>Lancement du service mail et préparation certifs dkim</h6>
                    <hr>
                    <strong>
                        Merci de bien ajouter l'entrée DNS des certificats DKIM chez votre fournisseur DNS :
                    </strong>
                    <br>
                    <strong><i class="fa fa-hand-point-right"></i> N.B :</strong> Vous trouverez l'entrée DNS dans le contenu du fichier suivant :
                    <p *ngIf="organization">Chemin du fichier : <code>/var/lib/docker/volumes/krisalee-admin-back_data-clients/_data/{{organization.organization_id}}/service/mail/mailserver/version/sauvegarde/data/config_mailserver/dms/opendkim/keys/{{organization.organization_env.ORG_DOMAIN}}/mail.txt</code></p>
                    <br><br>
                    <strong><i class="fa fa-hand-point-right"></i> N.B :</strong> Veuillez remplacer 'version' et 'sauvegarde' par les valeurs correspondant à votre environnement.
                    <br><br>
                    <!-- <pre class="bg-dark text-light">
                        <code>
                            mail._domainkey.{{organization.organization_id}} 10800 IN TXT "v=DKIM1; h=sha256; k=rsa; " "p=MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAnlJSRaozo6xXmHUVcRJUYmCRpMMBNpUNMzJpoglvI9mi/qsD1RzB+moSJI3BC1qIT79R8v+yG9Pw4+UMwKjM7OezdnxuLRJL9X9s0+z6ZkI1zMsuoqLLu0zomY4W+j3BhUtM7H5WUrmWmLqhLdQbpJyKd65O0kbdY7ZHjovA149ilQ2pG3w06eOVVdmrGSQwVyYPj+/EB+VQ8w" "Rnd0537CLjEXO/znHgcEamIam9t62Zj+Hy5W5lGnmIwkOjdULeTRbIVrXlkggXMpdVOaord1+i5FpBZl8Ckd6KT5V0Io3f6ChylRZ2ePbkYlzPBIyiyXwXB9GGzoI14kufcm4Bn+gMi9uTTWoEBQ9kCRrqMXAkyHv6tdwrDWdtlsX+B0/A7NVUxPLT149vIyGp1UPcxO6UaYK3qetvAF4hjcWYeX5FAP4Ecd0JEREykjphuounxqSGj7lc" "RhX41PCJuP3UCFoTeBZJjluJ65aVCuNLIIWw8NP3CzLntwfTeHmeDZZdQ1i3FPW19xVyd/ggnJkUajTEo9eodwLnS+eRUNmvn2TwkdKsZKTG9okYxn20EQatZDDCiydO8oGd08+NeQciR+Qcn7n3QLfLbpmOifBgDyNMelRQpP7ARVC54AXrZSThYe2Lhzq6okhBHyyRCMLrARjERV8y9yZwcYVgM4+iOgcCAwEAAQ=="
                        </code>
                    </pre> -->
                    <nb-checkbox [(ngModel)]="dkimCertsConfigured">
                        J'ai bien configuré les entrées DNS
                    </nb-checkbox>

                    <div class="step-buttons">
                        <button nbButton nbStepperPrevious>Précédent</button>
                        <button nbButton nbStepperNext [disabled]="!dkimCertsConfigured">Suivant</button>
                    </div>
                </nb-step>

                <!-- Étape 9 : Récapitulatif -->
                <nb-step [label]="recap">
                    <ng-template #recap>Récapitulatif</ng-template>
                    <h6>Récapitulatif</h6>
                    <hr>
                    <p>
                        <strong>
                            Opérations à vérifier : 
                        </strong>
                    </p>
                    <ul>
                        <li>Penser à tester la partie mailing avec mail tester</li>
                        <li>Penser à affecter les connecteurs aux organisations depuis la section gestion des connecteurs de la page d'administration.</li>
                    </ul>
                    <button nbButton nbStepperPrevious>Précédent</button>
                    <!-- <button nbButton status="success" type="submit">Valider</button> -->
                </nb-step>
            </nb-stepper>
        </nb-card-body>
        <nb-card-footer>
            <div class="btn-class">
                <button class="cancel" nbButton status="danger" (click)="cancel()">FERMER</button>
            </div>
        </nb-card-footer>
    </nb-card>
</div>
