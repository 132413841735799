export const environment = {
    API_URL: 'https://admin.dev.krisalee.fr/api',
    KC_URL: 'https://auth.admin.dev.krisalee.fr/',
    KC_REALM: 'master',
    KC_CLIENT_ID: 'krisalee-frontend',
    DIR: '/usr/src/app',
    NEXTCLOUD_URL: 'https://drive.dev.krisalee.fr/',
    SITE_INTERNE_URL: 'https://website.dev.krisalee.fr/',
    SITE_EXTERNE_URL: 'undefined',
    KEYCLOAK_URL: 'https://auth.dev.krisalee.fr/admin/apitech/console',
    MAILSERVER_URL: 'https://webmail.dev.krisalee.fr/',
    CHAT_URL: 'https://chat.dev.krisalee.fr/',
   production: 'false',
   ORGANIZATION_ID: 'undefined',
   ORGANIZATION_ADMIN_ID: 'undefined',
   PROD_TAG_ID: 'prod_1690553289436',
   alertUserId:'alert user',
   SEARCH_COLUMNS:['firstName', 'lastName', 'username', 'email'],
   ADMIN_APITECH_EMAIL:'mohamed.elmobarik@apitech.fr',
   IMPORT_LDAP_ID:'import ldap',
   K8S_PROVIDERS_LIST: ['ovh','apitech'],
};
