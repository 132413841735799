import { AbstractControl, ValidationErrors } from '@angular/forms';

export function urlValidator(control: AbstractControl): ValidationErrors | null {
  // regex to accept only urls
  const urlPattern = /^(https?:\/\/[^\s$.?#].[^\s]*)$/;
  if (!control.value || urlPattern.test(control.value)) {
    return null; // valid url
  }
  return { invalidUrl: true }; // invalid url
}
